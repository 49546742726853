<template lang="pug">
	transition(name="slide-fade")
		div
			loading(:active.sync="busy", :is-full-page="true")
			.widget
				.widget-header
					h1.title MMP Accounts
				.widget-body
					form.form.form-inline.ov-filters(@submit.prevent="loadData()")
						.form-row-main
							.form-group
								label Search
								input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")

							.form-group.no-label
								.button.btn.btn-primary(type="button", @click="loadData()") Go
						.form-row-end
							.form-group
								label &nbsp;
								//router-link.btn.btn-success(:to="{name:'mmp-account-add'}", v-if="USER.permissions['publishers::EDIT']")
								router-link.btn.btn-success(:to="{name:'mmp-account-add'}")
									i.la.la-plus
									| Add


					p.clearfix &nbsp;

					.ov-table-wrapper
						table.table.table-bordered
							thead
								tr
									th ID
									th Name
									th MMP
									th Created
									th Actions
							tbody
								tr(v-if="records.length===0")
									td(colspan="6") No matching records were found
								tr(v-for="r in records", v-bind:key="r.id")
									td {{ r.id }}
									td {{ r.account_name }}
									td {{ r.type }}
									td {{ r.created_at }}
									td.actions
										router-link.btn.btn-sm.btn-secondary(:to="{name:'mmp-account-edit', params: {id:r.id}}", v-b-tooltip.hover, title="Edit")
											i.la.la-pencil
										router-link.btn.btn-sm.btn-info(:to="{ name:'mmp-account-reports', query: { mmp_account_id: r.id } }", v-b-tooltip.hover, title="Reports")
											i.la.la-file-alt
										// a.btn.btn-sm.btn-secondary(href="javascript:void(0);", v-if="USER.permissions.admin_only")
											i.la.la-trash
						paginate(:paginator="paginate", @update-page-size="loadData")


</template>
<script>
export default {
  name: 'MmpAccountList',
  computed: {
    USER() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
      filters: {
        keyword: '',
      },
      records: [],
    };
  },

  methods: {
    async loadData() {
      this.busy = true;
      let params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
        keyword: this.filters.keyword,
      };

      try {
        let resp = await this.$ovReq.get('mmpAccount/getList', { params });
        this.records = resp.records;
        this.paginate.total = resp.total;
        this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.busy = false;
    },
  },
  created() {
    this.loadData();
  },
};
</script>
